/*******************************************************
    Swipper general
*******************************************************/
/***************************
    Pagination dynamic
****************************/
// .swiper-pagination-bullets-dynamic {
// 	.swiper-pagination-bullet {
// 		border-radius: 0px;
// 		height: 6px;
// 		width: 10px;
// 		background: $color__black-40;
//
// 		&.swiper-pagination-bullet-active-prev,
// 		&.swiper-pagination-bullet-active-next {
// 			opacity: 1;
// 		}
//
// 		&.swiper-pagination-bullet-active-main {
// 			background: $color__blue-dark;
// 			width: 28px;
// 		}
//
// 		&.swiper-pagination-bullet-active-prev-prev,
// 		&.swiper-pagination-bullet-active-next-next {
// 			opacity: 1;
// 		}
// 	}
//
// 	@media (screen(md)) {
// 		display: none !important;
// 	}
// }
/***************************
    Pagination
****************************/
// .swiper-pagination-bullets {
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: flex-end;
// 	bottom: 0px !important;
//
// 	.swiper-pagination-bullet {
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		width: 60px;
// 		height: 60px;
// 		text-align: center;
// 		border-radius: 0px;
// 		margin-left: 0px !important;
// 		margin-right: 0px !important;
// 		line-height: 1.625em;
// 		font-size: 1rem;
// 		font-weight: 500;
// 		color: $color__white;
// 		opacity: 1;
// 		background: $color__black;
// 	}
//
// 	.swiper-pagination-bullet-active {
// 		color: $color__black;
// 		text-decoration: underline;
// 	}
// }
/***************************
	Pagination Fraction
****************************/
.swiper-pagination-fraction {
	text-align: right !important;
	font-size: $font__size-14;
	color: $color__grey-medium;
	pointer-events: none;

	@media (screen(md)) {
		display: none !important;
	}
}

/***************************
    Previous / next button
****************************/
$btn-swiper-nav-width: 40px;
$btn-swiper-nav-height: 40px;

.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: auto !important;
	bottom: 0px !important;
	display: none !important;
	justify-content: center;
	align-items: center;
	width: $btn-swiper-nav-width !important;
	height: $btn-swiper-nav-height !important;
	cursor: pointer;
	background-color: $color__blue-dark;
	z-index: 10;
	transition: opacity 0.2s linear;

	@media (screen(md)) {
		display: flex !important;
	}
	
	&:after {
		font-family: 'Font Awesome\ 6 Pro';
		display: block;
		font-weight: 400;
		font-size: 16px !important;
		color: $color__white;
	}
	
	&:hover {
		opacity: 0.5;

		&:after {
			color: $color__white;
		}
	}
	
	&.swiper-button-lock {
		display: none !important;
	}
}

.swiper-button-prev {
	left: auto !important;
	right: calc(#{$btn-swiper-nav-width} + 10px) !important;

	&:after {
		// content: "\f053" !important;
	}
}

.swiper-button-next {
	right: 0px !important;
	left: auto !important;

	&:after {
		// content: "\f054" !important;
	}
}

/****************************************************
    Previous / next button
    Middle allignement grey
*****************************************************/
// .swiper-button-prev,
// .swiper-button-next {
// 	top: 50%;
// 	bottom: auto;
// 	background-color: $color__blue-dark;
// 	width: 40px !important;
// 	height: 40px !important;
//
// 	&:after {
// 		font-size: 24px !important;
// 	}
//
// 	&:hover {
// 		background-color: $color__black;
// 	}
// }
//
// .swiper-button-prev.middle {
// 	left: 0px;
//
// 	&:after {
// 		content: "\f177" !important;
// 	}
// }
//
// .swiper-button-next.middle {
// 	right: 0px;
//
// 	&:after {
// 		content: "\f178" !important;
// 	}
// }
/*******************************************************
    Swipper full width
*******************************************************/
.swiper-fullwidth,
.swiper-fullwidth-section {
	position: relative;
	width: 100%;
	max-width: 100%;
	min-width: 100%;
	// max-height: 350px;
	height: auto;
	overflow: hidden;
	padding: 0px;

	&:hover {
		.btn-open-lightgallery {
			i {
				color: $color__brown;
			}
		}
	}
	
	@media (min-width: 640px) {
		// max-height: 400px;
	}
	
	@media (min-width: 768px) {
		// max-height: 600px;
	}
	
	@media (min-width: 1024px) {
		// max-height: 750px;
	}
	
	@media (min-width: 1280px) {
		// max-height: 875px;
	}
	
	.swiper-wrapper {
		.swiper-slide {
			display: flex;
			justify-content: center;

			// align-items: center;
			img {
				width: auto;
				// max-height: 875px;
			}
		}
	}
}

.swiper-fullwidth-img-solo img {
	max-height: 350px;

	@media (min-width: 640px) {
		max-height: 400px;
	}
	
	@media (min-width: 768px) {
		max-height: 600px;
	}
	
	@media (min-width: 1024px) {
		max-height: 750px;
	}
	
	@media (min-width: 1280px) {
		max-height: 875px;
	}
}

.swiper-nav-wrapper {
	position: relative;
	padding: 0px 100px;
	overflow: hidden;

	&:before,
	&:after {
		position: absolute;
		top: 0px;
		content: "";
		display: block;
		width: 100px;
		height: 140px;
		z-index: 2;
	}
	
	&:before {
		left: 0px;
	}
	
	&:after {
		right: 0px;
	}
	
	&.dark {
		padding: 20px 100px 0px 100px;

		&:before,
		&:after {
			background-color: $color__black;
		}
	}
	
	&.light {
		background-color: $color__grey-light;

		.swiper-button-prev,
		.swiper-button-next {
			top: 37px;
			bottom: auto;
		}
		
		.swiper-button-prev {
			left: -100px;
			right: auto;
		}
		
		.swiper-button-next {
			right: -100px;
		}
		
		&:before,
		&:after {
			background-color: $color__grey-light;
		}
	}
	
	&.white {
		background-color: $color__white;

		.swiper-button-prev,
		.swiper-button-next {
			top: 37px;
			bottom: auto;
		}
		
		.swiper-button-prev {
			left: -100px;
			right: auto;
		}
		
		.swiper-button-next {
			right: -100px;
		}
		
		&:before,
		&:after {
			background-color: $color__white;
		}
	}
	
	.swiper-fullwidth-nav,
	.swiper-fullwidth-section-nav {
		position: relative;
		width: 100%;
		max-width: 100%;
		min-width: 100%;
		height: 140px;
		// background-color: $color__white;
		padding: 20px 0px;

		.swiper-wrapper {
			.swiper-slide {
				filter: brightness(0.6);
				transition: filter 0.2s linear;

				&.swiper-slide-thumb-active {
					filter: brightness(1);
				}
				
				.img-wrapper {
					img {
						// width: 100%;
						// height: 200px;
						// object-fit: cover;
						// object-position: center;
					}
				}
			}
		}
		
		.swiper-button-prev,
		.swiper-button-next {
			top: 80px;
			bottom: auto;

			&.swiper-button-lock {
				display: flex !important;
				opacity: 0.2;
			}
		}
		
		.swiper-button-prev {
			left: -100px;
			right: auto;
		}
		
		.swiper-button-next {
			right: -100px;
		}
	}
}

/***************************
	LightGallery
****************************/
// .btn-open-lightgallery {
// 	position: absolute;
// 	left: 20px;
// 	top: 10px;
// 	z-index: 20;
//
// 	i {
// 		color: $color__white;
// 		transition: $transition__color-link;
// 	}
//
// 	&:hover {
// 		i {
// 			color: $color__green-primary;
// 		}
// 	}
// }
.lg-container {
	.lg-outer {

		// z-index: 1000001;
		.lg-components {
			.lg-thumb-align-middle {
				.lg-thumb {
					.lg-thumb-item {
						border: 2px solid transparent;
						border-radius: 0px;
						opacity: 0.8;

						&:hover,
						&.active {
							opacity: 1;
							border-color: $color__brown;
						}
					}
				}
			}
		}
	}
}