/*--------------------------------------------------------------
## Map
--------------------------------------------------------------*/
.gm-map {
	height: 70vh;
}

/*--------------------------------------------------------------
## Scrollbar results
--------------------------------------------------------------*/
.scrollbar-wrapper::-webkit-scrollbar {
	// width: 1.5em;
	height: 1.5em;
}

.scrollbar-wrapper::-webkit-scrollbar-track {
	// box-shadow: inset 0 0 6px $color__brown;
	border-top: 2px solid $color__brown;
	border-bottom: 2px solid $color__brown;
}

.scrollbar-wrapper::-webkit-scrollbar-thumb {
	background-color: $color__brown;
	// outline: 2px solid $color__brown;
	border-top: 2px solid $color__brown;
}