// ==========================================================================
// Variables
// ==========================================================================
/*--------------------------------------------------------------
## Colors
--------------------------------------------------------------*/
$color__white: #ffffff;
$color__black: #000000;
$color__white-50: #8E8E8E;
$color__black-80: rgba(0, 0, 0, 0.8);
$color__black-70: rgba(0, 0, 0, 0.7);
$color__black-65: rgba(0, 0, 0, 0.65);
$color__black-60: rgba(0, 0, 0, 0.6);
$color__black-40: rgba(0, 0, 0, 0.4);
$color__black-35: rgba(0, 0, 0, 0.35);
$color__black-30: rgba(0, 0, 0, 0.3);
$color__black-25: rgba(0, 0, 0, 0.25);
$color__black-20: rgba(0, 0, 0, 0.2);
$color__black-10: rgba(0, 0, 0, 0.1);
$color__black-05: rgba(0, 0, 0, 0.05);
$color__grey-medium: #707070;
$color__grey-light: #F5F4F4;
$color__brown: #9C6D61;
$color__blue-sky: #C4DEE8;
$color__blue-dark: #1A4759;
// $color__red-pink: #ea2126;
$color__red-error: #ea2126;
/*--------------------------------------------------------------
## typography
--------------------------------------------------------------*/
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
$font__fa-familly: Font Awesome\ 6 Pro;
$font__main: "acumin-pro", helvetica, arial, sans-serif;
$font__mono: monospace;
$font__heading: "kaftan", helvetica, arial, sans-serif;
$font__size-body: 16px;
$font__size-14: 0.875rem;
$font__size-13: 0.8125rem;
$font__size-12: 0.75rem;
$font__code: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
$font__pre: 'Courier 10 Pitch', Courier, monospace;
$font__line-height-body: 1.5em;
// $font__line-height-pre: 1.6;
$font__line-height-reg: 1.3em;
$font__weight-bold: 700;
$font__weight-semibold: 600;
$font__weight-medium: 500;
$font__weight-regular: 400;
$font__top-position-correction: 0.11em;
/*--------------------------------------------------------------
## transitions
--------------------------------------------------------------*/
$transition__color-link: color 0.2s linear;
$transition__opacity: opacity 0.2s linear;
$transition__color-back: background-color 0.2s linear;
$transition__color-border: border 0.2s linear;
$transition__transform: transform 0.2s linear;
$transition__box-shadow: box-shadow 0.2s linear;
// $transition__transform-nav: transform 0.4s linear;
// $transition__transform-nav-fast: transform 0.1s ease-in-out;
// Easings
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1); // https://cubic-bezier.com/#.65,0,.35,1
$ease-in-out-airbag: cubic-bezier(0.3, 0, 0, 1); // https://cubic-bezier.com/#.3,0,0,1
/*--------------------------------------------------------------
## Structure
--------------------------------------------------------------*/
$promo-bar-height: 50px;
/*--------------------------------------------------------------
## Border radius
--------------------------------------------------------------*/
// $radius-button: 8px;
// $radius-tabs: 8px;
// $radius-form: 4px;
// $radius-cards: 6px;
// $radius-cards-blog: 10px;
/*--------------------------------------------------------------
## Box shadow
--------------------------------------------------------------*/
// $boxshadow__card-medium: 0px 0px 8px 1px $color__black-10;
// $boxshadow__card-medium-hover: 0px 0px 20px 1px $color__black-20;
/*--------------------------------------------------------------
## z-index
--------------------------------------------------------------*/
// $zindex__navbar-main: 1100;