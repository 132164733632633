* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	scroll-behavior: smooth;
}

body {
	width: 100%;
	background-color: $color__white;
	overflow-x: hidden;
	scroll-behavior: smooth;
}

.page__wrapper {
	// overflow-x: hidden; /* fix for ios safari */
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
	max-width: 100%;
	height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.bg-color__black-40 {
	background-color: rgba(0, 0, 0, 0.4);
}