/************************************************************************
	font-family: 'kaftan'
	regluar: 400
************************************************************************/
@font-face {
	font-family: 'kaftan';
	src: url('/frontend/fonts/kaftan-webfont.woff2') format('woff2'),
		url('/frontend/fonts/kaftan-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	display: swap;
}

/************************************************************************
	font-family: Acumin
	regluar: 400
************************************************************************/
@import url("https://use.typekit.net/abm3hvb.css");