@config "../../tailwindcss-config.js";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
// Include any default variable overrides here
@import 'variables';
/* --------------------------------------------------------------
# Fontawesome Pro 6.4
-------------------------------------------------------------- */
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
// @import '~@fortawesome/fontawesome-pro/scss/thin';
// @import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/regular';
// @import '~@fortawesome/fontawesome-pro/scss/solid';
// @import '~@fortawesome/fontawesome-pro/scss/duotone';
// @import '~@fortawesome/fontawesome-pro/scss/brands';
// @import '~@fortawesome/fontawesome-pro/scss/sharp-light';
@import '~@fortawesome/fontawesome-pro/scss/sharp-solid';
@import '~@fortawesome/fontawesome-pro/scss/sharp-regular';
/* --------------------------------------------------------------
# Swiper 11.0.1
-------------------------------------------------------------- */
// imported in JS
// @import '~swiper/swiper-bundle.css';
/* --------------------------------------------------------------
# Lightgallery
	!! importe in main.js
-------------------------------------------------------------- */
// @import '~lightgallery/css/lightgallery.css';
// @import '~lightgallery/css/lg-fullscreen.css';
// @import '~lightgallery/css/lg-zoom.css';
// @import '~lightgallery/css/lg-thumbnail.css';
/*******************************************************
    Reset
*******************************************************/
@import 'reset';
/*******************************************************
    Global
*******************************************************/
@import 'global';
/*******************************************************
    Font
*******************************************************/
@import 'fonts';
/*******************************************************
    Typography
*******************************************************/
@import 'typography';
/*******************************************************
	Animations
*******************************************************/
@import 'animations';
/*******************************************************
/*******************************************************
	Typography
*******************************************************/
@import 'text-editor';
/*******************************************************
	Forms
*******************************************************/
@import 'forms';
/*******************************************************
	Tailwind-overwrite
*******************************************************/
@import '_tailwind-overwrite';
/*******************************************************
	Matrix
*******************************************************/
@import '_matrix';
/*******************************************************
	Swiper
*******************************************************/
@import 'swiper';
/*******************************************************
	Map
*******************************************************/
@import 'map';