/*--------------------------------------------------------------
## General styles
--------------------------------------------------------------*/
.text-editor {
	&.text-white {

		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $color__white;
		}
	}
	
	&.text-blue-dark {

		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $color__blue-dark;
		}
	}
	
	&.mb-0 {

		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 0px;
		}
	}
	
	ul,
	ol {
		list-style: disc;
		color: inherit;
		padding-left: 20px;

		li {
			padding-bottom: 8px;

			ul,
			ol {
				padding-top: 12px;
			}
		}
	}
}

.text-editor-img {
	p {
		font-family: "kaftan", helvetica, arial, sans-serif;
		font-size: 1.5rem;
		font-weight: $font__weight-regular;
		line-height: 1.25em;
		color: $color__blue-dark;
		margin-bottom: 0px;

		@media (screen(md)) {
			font-size: 2rem;
		}
	}
}