/*--------------------------------------------------------------
## Hover animations
--------------------------------------------------------------*/
.card {
	&.card-pdf {
		.icon-link-wrapper {
			position: relative;

			&:before,
			&:after {
				position: absolute;
				display: block;
				content: "";
				background-color: $color__white;
			}
			
			&:before {
				height: 2px;
				width: 16px;
				transition: width 0.2s linear;
			}
			
			&:after {
				width: 2px;
				height: 16px;
				transition: transform 0.2s linear, transform 0.2s linear;
			}
		}
		
		&:hover {
			.icon-link-wrapper {
				&:before {
					width: 20px;
					transform: translateX(-4px);
				}
				
				&:after {
					width: 12px;
					height: 12px;
					background-color: transparent;
					border-top: 2px solid $color__white;
					border-right: 2px solid $color__white;
					transform: rotate(45deg);
				}
			}
		}
	}
}

/*--------------------------------------------------------------
Card POOL
## Hover animations
--------------------------------------------------------------*/
.card-pool {
	.icon-link-wrapper {
		position: relative;

		&:before {
			position: absolute;
			top: 33px;
			display: block;
			content: "";
			height: 20px;
			width: 20px;
			border-top: 3px solid $color__white;
			border-right: 3px solid $color__white;
			transform: rotate(45deg) translateX(0);
			transform-origin: top right;
			transition: transform 0.3s linear;
		}
	}
	
	&:hover {
		.icon-link-wrapper {
			&:before {
				transform: rotate(45deg) translateX(4px) translateY(-4px);
			}
		}
	}
}

/*--------------------------------------------------------------
## Loader animation
--------------------------------------------------------------*/
.loader-pool {
	font-size: 8rem;
	animation-duration: 1.25s;
	animation-iteration-count: infinite;
	animation-name: loaderLoop;
}

@keyframes loaderLoop {
	0% {
		color: #FFFFFF;
	}
	
	50% {
		color: #34FBD8;
	}
	
	100% {
		color: #FFFFFF;
	}
}

/*--------------------------------------------------------------
## Loader client area
--------------------------------------------------------------*/
.loader-wrapper-client {
	position: absolute;
	// display: none;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	opacity: 0;
	pointer-events: none;
	z-index: 40;
	transition: opacity 0.1s linear;

	&.htmx-request {
		opacity: 0.4;
		background-color: $color__black;
		pointer-events: auto;
	}
}